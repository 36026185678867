import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { LogInRole, useGetLoginStatusQuery } from '../../generated/types'
import MenuBar from './components/menubar/menubar'

export default function Layout () {
  const { data: userLoggedIn } = useGetLoginStatusQuery()
  const [search, setSearch] = useState('')

  const navigation = [
    { name: 'News', href: '/stories' },
    { name: 'Feedback', href: '/feedback' }
  ]

  if (userLoggedIn?.getLoginStatus.role === LogInRole.Superadmin) {
    navigation.push(
      {
        name: 'Settings',
        href: 'settings'
      }
    )
  }

  return (
        <>
            <div className="min-h-full">
                <MenuBar navigation={navigation} search={search} setSearch={setSearch}/>

                <main className='bg-gray-100 dark:bg-slate-900'>
                    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                        <Outlet context={search}/>
                    </div>
                </main>
            </div>
        </>
  )
}
