import moment from 'moment'
import { ArticleFragment, CveFragment } from '../../../generated/types'

const timeAgo = (date: (string | null | undefined)) => {
  const duration = moment.duration(moment(new Date()).diff(date))
  if (duration.asDays() > 2) {
    return Math.round(duration.asDays()) + ' days ago'
  } else if (duration.asHours() > 1) {
    return Math.round(duration.asHours()) + ' hours ago'
  } else {
    return Math.round(duration.asMinutes()) + ' minutes ago'
  }
}

export default function StoryContent ({ article }: {article: ArticleFragment | CveFragment}) {
  return (
        <div className="relative p-2 row-start-1 sm:col-start-3">
            <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                    <div className="block focus:outline-none">
                        <p className="text-sm font-medium text-gray-900 dark:text-slate-200">{article.title}</p>
                        <p className="text-sm text-gray-500 dark:text-slate-400 truncate">{'source' in article ? article.source.name : article.cveId}</p>
                    </div>
                </div>
                <time dateTime={moment(article.publishedAt).format()} className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 dark:text-slate-400">
                    {timeAgo(article.publishedAt)}
                </time>
            </div>
            <div className="mt-2">
                <p className="line-clamp-2 text-sm text-gray-600 dark:text-slate-400">{article.summary}</p>
                <a target="_blank" rel="noopener noreferrer" href={article.url} className="text-sm underline text-blue-600 visited:text-purple-600">Read more</a>
            </div>
        </div>
  )
}
