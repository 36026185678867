import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon
} from '@heroicons/react/solid'
import moment from 'moment'

export default function Pagination ({ date, setDate }: {date: Date, setDate: (date: Date) => unknown}) {
  return (
    <>
      <nav className="border-t border-gray-200 dark:border-gray-400 bg-gray-100 dark:bg-gray-900 fixed bottom-0 right-0 left-0 pb-4 px-4 sm:px-14 lg:px-16 flex items-end justify-between">
        <div className="-mt-px w-0 flex-1 flex">
          <button
            onClick={() => setDate(moment(date).add(-1, 'days').max(new Date()).toDate())}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 dark:text-slate-400 hover:text-gray-700 dark:hover:text-slate-200 hover:border-gray-300 dark:hover:border-gray-600"
          >
            <ArrowNarrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Previous
          </button>
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-center">
          <input
            onClick={(e) =>
              // @ts-ignore
              e.currentTarget.showPicker()
            }
            className="dark:picker-dark cursor-pointer p-0 text-gray-500 hover:text-gray-700 border-transparent bg-transparent focus:outline-none focus:border-transparent focus:ring-transparent inline-flex items-center text-sm font-medium active:select-none focus:select-none select-none"
            type="date"
            value={moment(date).format('yyyy-MM-DD')}
            onChange={(e) => setDate(moment(e.currentTarget.value).toDate())}
            max={moment(new Date()).format('yyyy-MM-DD')}
          />
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <button
            onClick={() => setDate(moment(date).add(1, 'days').max(new Date()).toDate())}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 dark:text-slate-400 hover:text-gray-700 dark:hover:text-slate-200 hover:border-gray-300 dark:hover:border-gray-600"
          >
            Next
            <ArrowNarrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
    </>
  )
}
