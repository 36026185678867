import { Fragment } from 'react'
import { Menu, Transition, Disclosure } from '@headlessui/react'
import { NavLink, useNavigate } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/solid'

export default function User ({
  mobile,
  userNavigation,
  isLoggedIn
}: {
    mobile: boolean;
    userNavigation: { name: string; href: string }[];
    isLoggedIn: boolean;
}) {
  const navigate = useNavigate()
  if (mobile) {
    return (
            <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                        {isLoggedIn && (
                            <UserCircleIcon className="h-10 w-10 text-white" />
                        )}
                    </div>
                    <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">
                        </div>
                    </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                    {userNavigation.map((item) => (
                        <Disclosure.Button
                            key={item.name}
                            as={NavLink}
                            to={item.href}
                            className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        >
                            {item.name}
                        </Disclosure.Button>
                    ))}
                </div>
            </div>
    )
  }

  return (
        <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                    <div className="flex gap-2">
                        <Menu.Button
                            className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                            onClick={() => navigate('/login')}
                        >
                            <span className="sr-only">Open user menu</span>
                            <UserCircleIcon className="h-8 w-8 text-white" />
                        </Menu.Button>
                    </div>
                    {isLoggedIn && (
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {userNavigation.map((item) => (
                                    <Menu.Item key={item.name}>
                                        {({ active }) => (
                                            <NavLink
                                                to={item.href}
                                                className={() =>
                                                  active
                                                    ? 'bg-gray-100 block px-4 py-2 text-sm text-gray-700'
                                                    : 'block px-4 py-2 text-sm text-gray-700'
                                                }
                                            >
                                                {item.name}
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                ))}
                            </Menu.Items>
                        </Transition>
                    )}
                </Menu>
            </div>
        </div>
  )
}
