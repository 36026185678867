import { NavLink } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'

function Logo () {
  return (
        <a className="flex-shrink-0" href="/">
            <img
            className="h-8 w-8"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
            alt="Workflow"
            />
        </a>
  )
}

interface Item {
    name: string,
    href: string
}

function MenuItem ({ item } : {item: Item}) {
  return (
        <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              isActive
                ? 'bg-gray-900 dark:bg-black text-white px-3 py-2 rounded-md text-sm font-medium'
                : 'text-gray-300 hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
            }>
            {item.name}
        </NavLink>
  )
}

export default function MenuItems ({ mobile, navigation }: {mobile: boolean, navigation: Item[]}) {
  if (mobile) {
    return (
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                <Disclosure.Button
                    key={item.name}
                    as={NavLink}
                    to={item.href}
                    // @ts-ignore
                    className={({ isActive }) =>
                      isActive
                        ? 'bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                    }
                >
                    {item.name}
                </Disclosure.Button>
                ))}
            </div>
    )
  }

  return (
        <div className="flex items-center">
            <Logo />
            <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                {navigation.map((item: Item) => (
                    <MenuItem key={item.name} item={item} />
                ))}
                </div>
            </div>
        </div>
  )
}
