import { useSearchParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

type MagicJWTPayload = {
  userMail: string;
  userId: string;
  createdAt: string;
  requestIp: string;
};

export default function Confirmation ({ signIn, signInState }: {signIn: ({ variables }: {variables: any}) => void, signInState: {loading: boolean, data: any}}) {
  const [searchParams] = useSearchParams()

  const rawMagicLinkToken = searchParams.get('code')
  const magicLinkJwt = rawMagicLinkToken
    ? (jwtDecode(rawMagicLinkToken) as MagicJWTPayload)
    : undefined

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-2xl text-gray-900 dark:text-slate-100">
            <p>Please confirm your sign in request for</p>
            <p className="font-bold">{magicLinkJwt?.userMail}</p>
            <p>at</p>
            <p className="font-bold">{magicLinkJwt ? new Date(magicLinkJwt.createdAt).toLocaleString() : null}</p>
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={signInState.loading}
              onClick={() => {
                if (!rawMagicLinkToken) return
                signIn({
                  variables: {
                    magicToken: rawMagicLinkToken
                  }
                })
              }}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              </span>
              {!signInState.loading && !signInState.data
                ? 'Confirm'
                : 'Please wait...'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
