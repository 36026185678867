import { Fragment, useState } from 'react'
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition
} from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { FilterType } from '../stories'

function classNames (...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Filter ({
  sortOptions,
  selectedSort,
  setSelectedSort,
  filters,
  setFilters
}: {
  sortOptions: string[];
  selectedSort: number;
  setSelectedSort: (selectedSort: number) => void;
  filters: FilterType[];
  setFilters: (filters: FilterType[]) => void;
}) {
  const [open, setOpen] = useState(false)

  return (
    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white dark:bg-gray-800 shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900 dark:text-slate-400">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white dark:bg-gray-800 p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((section, sectionIdx) => (
                    <Disclosure
                      as="div"
                      key={section.name}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="px-2 py-3 bg-white dark:bg-gray-800 dark:text-white w-full flex items-center justify-between text-sm text-gray-400">
                              <span className="font-medium text-gray-900 dark:text-white">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? '-rotate-180' : 'rotate-0',
                                    'h-5 w-5 transform'
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    checked={option.selected}
                                    onChange={() =>
                                      setFilters([
                                        ...filters.slice(0, sectionIdx),
                                        {
                                          ...section,
                                          options: [
                                            ...section.options.slice(0, optionIdx),
                                            {
                                              ...option,
                                              selected: !option.selected
                                            },
                                            ...section.options.slice(
                                              optionIdx + 1,
                                              section.options.length
                                            )
                                          ]
                                        },
                                        ...filters.slice(
                                          sectionIdx + 1,
                                          filters.length
                                        )
                                      ])
                                    }
                                    type="checkbox"
                                    className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-500 dark:text-gray-200"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="mt-6">
        <section aria-labelledby="filter-heading" className="">
          <h2 id="filter-heading" className="sr-only">
            Stories filters
          </h2>

          <div className="flex items-center justify-between">
            <Menu as="div" className="relative z-10 inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-slate-400 dark:hover:text-slate-200">
                  Sort
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-slate-400 dark:group-hover:text-slate-200"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-slate-200 dark:bg-slate-800 ring-1 ring-black dark:ring-white ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option, idx) => (
                      <Menu.Item key={option}>
                        {({ active }) => (
                          <div
                            className={`block px-4 py-2 text-sm font-medium text-gray-900 dark:text-slate-200 cursor-pointer ${active ? 'bg-gray-100 dark:bg-gray-900' : ''}`}
                            onClick={() => setSelectedSort(idx)}
                          >
                            {option}
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-slate-400 dark:hover:text-slate-200 sm:hidden"
              onClick={() => setOpen(true)}
            >
              Filters
            </button>

            <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
              {filters.map((section, sectionIdx) => (
                <Popover
                  as="div"
                  key={section.name}
                  id={`desktop-menu-${sectionIdx}`}
                  className="relative z-10 inline-block text-left"
                >
                  <div>
                    <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-slate-400 dark:hover:text-slate-200">
                      <span>{section.name}</span>
                      {section.options.filter((o) => o.selected).length > 0
                        ? (
                        <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                          {section.options.filter((o) => o.selected).length}
                        </span>
                          )
                        : null}
                      <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-slate-200"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white dark:bg-slate-800 rounded-md shadow-2xl p-4 ring-1 ring-black dark:ring-white ring-opacity-5 focus:outline-none">
                      <form className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              checked={option.selected}
                              onChange={() =>
                                setFilters([
                                  ...filters.slice(0, sectionIdx),
                                  {
                                    ...section,
                                    options: [
                                      ...section.options.slice(0, optionIdx),
                                      {
                                        ...option,
                                        selected: !option.selected
                                      },
                                      ...section.options.slice(
                                        optionIdx + 1,
                                        section.options.length
                                      )
                                    ]
                                  },
                                  ...filters.slice(
                                    sectionIdx + 1,
                                    filters.length
                                  )
                                ])
                              }
                              type="checkbox"
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 pr-6 text-sm font-medium text-gray-900 dark:text-slate-200 whitespace-nowrap"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </form>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ))}
            </Popover.Group>
          </div>
        </section>
      </div>
    </div>
  )
}
