import { ArrowRightIcon } from "@heroicons/react/outline";
import { BookmarkIcon, StarIcon } from "@heroicons/react/solid";
import { ReactNode } from "react";

export const About = () => {
    const sources = [
        {
            title: "Packetstorm",
            description: "What you don't know can hurt you",
        },
        {
            title: "Blackhat",
            description: "What you don't know can hurt you",
        },
        {
            title: "IT-Security",
            description: "What you don't know can hurt you",
        },
        {
            title: "Security Today",
            description: "What you don't know can hurt you",
        },
        {
            title: "Security News",
            description: "What you don't know can hurt you",
        },
        {
            title: "SecNews",
            description: "What you don't know can hurt you",
        },
        {
            title: "Blackhat",
            description: "What you don't know can hurt you",
        },
        {
            title: "IT-Security",
            description: "What you don't know can hurt you",
        },
        {
            title: "Security Today",
            description: "What you don't know can hurt you",
        },
        {
            title: "Security News",
            description: "What you don't know can hurt you",
        },
        {
            title: "SecNews",
            description: "What you don't know can hurt you",
        },
        {
            title: "SecNews",
            description: "What you don't know can hurt you",
        },
        {
            title: "IT-Security",
            description: "What you don't know can hurt you",
        },
        {
            title: "Security Today",
            description: "What you don't know can hurt you",
        },
        {
            title: "Security News",
            description: "What you don't know can hurt you",
        },
        {
            title: "SecNews",
            description: "What you don't know can hurt you",
        },
    ];
    const nrOfSources = sources.length;
    return (
        <div className="px-10 sm:p-0">
            <div className="text-black dark:text-slate-100 mt-[10vh] mb-[10vh]">
                <span className="text-4xl font-bold">
                    Your{" "}
                    <span className="before:block before:absolute before:h-3 before:mt-8 before:-inset-1 before:bg-indigo-400 dark:before:bg-indigo-600 relative inline-block">
                        <span className="relative text-black dark:text-white">
                            one stop
                        </span>
                    </span>{" "}
                    for all IT Security News.
                </span>
                <br></br>
                <span className="text-lg font-medium">
                    We aggregate {nrOfSources} IT Security News sources so you
                    don't mentally have to.
                </span>
                <a href="/stories" className="font-medium mt-3 bg-indigo-600 text-slate-100 w-40 flex items-center justify-center gap-2 py-1.5 px-3 rounded-md">
                    Start Reading
                    <ArrowRightIcon className="w-4" />
                </a>
            </div>
            <div className="text-black dark:text-slate-100 my-16">
                <div className="text-slate-600 dark:text-slate-400 font-medium text-xs uppercase mb-1">
                    WHAT WE DO AND WHY
                </div>
                It is critical for IT security professionals to stay up to date 
                on the latest threats and vulnerabilities. This often means spending 
                hours every week checking hundres of news sites and vulnerability advisories.
                We make this easier by aggregating the news from{" "}
                {nrOfSources} sources by shared topics so you don't read
                anything twice, but dont miss anything either. We also extract
                and highlight CVE's automatically.
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 items-start gap-3 text-black dark:text-slate-100 mt-8 w-full max-w-[100vw]">
                <div className="col-span-full text-slate-600 dark:text-slate-400 font-medium text-xs uppercase -mb-1">
                    FEATURES
                </div>

                <Card
                    title="CVEs"
                    description={
                        "We automatically extract and match CVEs and allow you to filter and search."
                    }
                    containerClassName="flex flex-col gap-2.5"
                >
                    <div className="flex gap-2 -ml-12">
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-XXXX-XXX
                        </div>
                    </div>
                    <div className="flex gap-2 -ml-2 opacity-90">
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-XXXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            CVE-123-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-ABC
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-XXXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-DEFGH
                        </div>
                    </div>
                    <div className="flex gap-2 -ml-6 opacity-60">
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-DEFGH
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-ABC
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            CVE-123-X
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-DEFGH
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-ABC
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            CVE-123-X
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-DEFGH
                        </div>
                    </div>
                    <div className="flex gap-2 -ml-2 opacity-40">
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-XXXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            CVE-123-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-ABC
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            CVE-XXXX-XXX
                        </div>
                        <div className="shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            CVE-XXXX
                        </div>
                    </div>
                </Card>

                <Card
                    title={`${nrOfSources} Sources`}
                    description={"We crawl the web, so you can stay focused."}
                >
                    <div className="-mx-8 gap-2.5 grid grid-cols-3">
                        <div className="shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            IT-Security
                        </div>
                        <div className="shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            Darkhat
                        </div>
                        <div className="shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            Blackhat
                        </div>
                        <div className="opacity-90 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            Packetloss
                        </div>
                        <div className="opacity-90 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            Hacked
                        </div>
                        <div className="opacity-90 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            Hackernews
                        </div>
                        <div className="opacity-60 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            Hacker Weekly
                        </div>
                        <div className="opacity-60 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                            IT Security
                        </div>
                        <div className="opacity-60 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            Security Review
                        </div>
                        <div className="opacity-40 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                            Security Daily
                        </div>
                        <div className="opacity-40 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                            TodaysSecurity
                        </div>
                        <div className="opacity-40 shrink-0 whitespace-nowrap rounded-full py-0.5 px-1.5 text-xs bg-green-400">
                            IT News
                        </div>
                    </div>
                </Card>

                <Card
                    title={"Aggregation"}
                    description={
                        "News are matched by topic, so you read about everything, but nothing twice."
                    }
                >
                    <div className="grid grid-cols-4 relative">
                        <div className="h-[7rem] flex flex-col items-end text-right overflow-hidden justify-between ">
                            <div className="w-[120%] shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-yellow-400">
                                Article A
                            </div>
                            <div className="opacity-90 w-[120%] shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-orange-400">
                                Article B
                            </div>
                            <div className="opacity-40 w-[120%] shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                                Article C
                            </div>
                        </div>
                        <div className="h-[7rem] col-span-2 flex items-center justify-center">
                            <svg
                                width="100%"
                                height="calc(100% - 14px)"
                                viewBox="0 0 102 102"
                                preserveAspectRatio="none"
                                className="stroke-slate-400 dark:stroke-white"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M 0   1
                                       C 40  1
                                         60  51
                                         102 51"
                                    fill="transparent"
                                />
                                <path d="M 0 51 102 51"></path>
                                <path
                                    d="M 0   100
                                       C 40  100
                                         60  51
                                         102 51"
                                    fill="transparent"
                                />
                            </svg>
                        </div>
                        <div className="h-[7rem] flex flex-col overflow-hidden justify-around">
                            <div className="w-[120%] shrink-0 rounded-full py-0.5 px-1.5 text-xs bg-blue-400">
                                One Story
                            </div>
                        </div>
                    </div>
                </Card>

                <Card
                    title="Filter, Search, Bookmark"
                    description="So you dont get lost in the web."
                >
                    <div className="">
                        <div className="pl-3 ml-4 -mr-4 border  dark:border-slate-800 dark:text-slate-400 rounded-full px-1.5 py-0.5 bg-gray-100 border-gray-200 dark:bg-slate-700">
                            Log4J
                        </div>
                    </div>
                    <div className="px-4 flex flex-col gap-1 pt-2 items-start">
                        <div className="-mr-4 justify-center flex items-center gap-2 overflow-hidden">
                            <div className="text-black dark:text-slate-100  shrink-0 rounded-full gap-1 py-0.5 px-1.5 text-xs bg-blue-400 flex items-center">
                                <StarIcon className="w-4" />
                                First report: Log4J
                            </div>
                            <div className="whitespace-nowrap overflow-hidden text-slate-600 dark:text-slate-200">
                                In a twist of events, you can now patch Log4J
                                using the Log4J vulnerability. Check it out
                                here.
                            </div>
                        </div>
                        <div className="-mr-4 justify-center text-slate-200 flex items-center gap-2 overflow-hidden">
                            <div className="text-black dark:text-slate-100  shrink-0 rounded-full gap-1 py-0.5 px-1.5 text-xs bg-blue-400 flex items-center opacity-60">
                                <BookmarkIcon className="w-4" />
                                Log4J's fix
                            </div>
                            <div className="whitespace-nowrap overflow-hidden text-slate-400 dark:text-slate-400">
                                A local government has lost all employee data
                                after hiring employee called "bobby tables"
                            </div>
                        </div>
                        <div className="-mr-4 justify-center text-slate-200 flex items-center gap-2 overflow-hidden">
                            <div className="text-black dark:text-slate-100  shrink-0 rounded-full gap-1 py-0.5 px-1.5 text-xs bg-blue-400 flex items-center opacity-40">
                                <StarIcon className="w-4" />
                                Not yet fixed
                            </div>
                            <div className="whitespace-nowrap overflow-hidden text-slate-200 dark:text-slate-600">
                                Log4J is back, and back with a vengance.
                                Companies are urged to keep an eye on the issue
                                again.
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 text-black dark:text-slate-100 mt-16">
                <div className="col-span-full text-slate-600 dark:text-slate-400 font-medium text-xs uppercase -mb-1">
                    SOURCES
                </div>
                {sources.map((m) => (
                    <div
                        className="p-4 bg-white dark:bg-slate-800 rounded-md"
                        key={m.title}
                    >
                        <div className="font-bold text-lg">{m.title}</div>
                        <div className="text-sm">{m.description}</div>
                    </div>
                ))}
            </div>
            <div className="text-black dark:text-slate-100 flex flex-col text-xs mt-8 border-t border-gray-600 pt-3 w-fit">
                <div className="flex">
                    <div className="w-3">
                        <sup>1</sup>
                    </div>
                    <div>As measured by CVEs submitted.</div>
                </div>
            </div>
        </div>
    );
};

const Card = ({
    title,
    description,
    children,
    containerClassName,
}: {
    title: string;
    description: string;
    children: ReactNode;
    containerClassName?: string;
}) => {
    return (
        <div className="bg-white dark:bg-slate-800 rounded-md flex flex-col max-w-[100%] overflow-hidden">
            <div className="pt-4 px-4 font-bold text-lg h-12 overflow-hidden text-ellipsis">
                {title}
            </div>
            <div className="px-4 text-sm sm:h-12 lg:h-14">{description}</div>
            <hr className="mt-4 mb-4 border-gray-300 dark:border-gray-700"></hr>
            <div
                className={`h-32 overflow-hidden max-w-full gap-2 ${containerClassName}`}
            >
                {children}
            </div>
        </div>
    );
};
