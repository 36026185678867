import moment from 'moment'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useGetSourcesQuery, useGetStoriesQuery } from '../../generated/types'
import Filter from './components/filter'
import LoginDialog from './components/logindialog'
import Pagination from './components/pagination'
import Story from './components/story'
import StorySkeleton from './components/storyskeleton'

export type FilterType = {
  id: string;
  name: string;
  options: { value: string; label: string; selected: boolean }[];
};

const sortOptions: string[] = [
  'Newest',
  'Most mentioned'
]

export default function Stories () {
  const search: string = useOutletContext()
  const [show, setShow] = useState<boolean>(false)

  const [selectedSort, setSelectedSort] = useState<number>(0)

  const [filters, setFilters] = useState<FilterType[]>([
    {
      id: 'read',
      name: 'Read',
      options: [
        { value: 'read', label: 'Read', selected: false },
        { value: 'notread', label: 'Not Read', selected: false }
      ]
    },
    {
      id: 'bookmarked',
      name: 'Bookmarked',
      options: [
        { value: 'bookmarked', label: 'Bookmarked', selected: false },
        { value: 'notbookmarked', label: 'Not Bookmarked', selected: false }
      ]
    },
    {
      id: 'source',
      name: 'Source',
      options: []
    }
  ])

  useGetSourcesQuery({
    onCompleted: (data) => {
      const options = data.getSources.map((source) => ({
        value: source.id,
        label: source.name,
        selected: false
      }))
      setFilters([
        ...filters.slice(0, 2),
        {
          id: 'source',
          name: 'Source',
          options
        }
      ])
    }
  })

  const [date, setDate] = useState<Date>(new Date())

  const { loading, error, data } = useGetStoriesQuery({
    variables: {
      search: search.trim(),
      date:
        search !== ''
          ? null
          : moment(date).startOf('day').toDate().toISOString()
    }
  })

  if(error) {
    console.error(error);
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-slate-200">
              News Stories
            </h1>
            <p className="mt-2 text-sm text-gray-700 dark:text-slate-400">
              Latest security news grouped by news story (i.e., same
              vulnerability/incident).
            </p>
          </div>
        </div>
        <Filter sortOptions={sortOptions} selectedSort={selectedSort} setSelectedSort={setSelectedSort} filters={filters} setFilters={setFilters} />
        <div className="-mx-6 mt-2 pb-8 px-2 overflow-hidden sm:-mx-2 sm:overflow-auto ">
          {loading
            ? [...Array(10)].map((e, i) => <StorySkeleton key={i} />)
            : data?.getStories
              .filter((story) => {
                // story was read
                if (
                  filters[0].options.some(o => o.selected) &&
                    story.userToStory?.isSeen &&
                    !filters[0].options[0].selected
                ) { return false }

                // story was not read
                if (
                  filters[0].options.some(o => o.selected) &&
                    !story.userToStory?.isSeen &&
                    !filters[0].options[1].selected
                ) { return false }

                // story was bookmarked
                if (
                  filters[1].options.some(o => o.selected) &&
                    story.userToStory?.isBookmarked &&
                    !filters[1].options[0].selected
                ) { return false }

                // story was not bookmarked
                if (
                  filters[1].options.some(o => o.selected) &&
                    !story.userToStory?.isBookmarked &&
                    !filters[1].options[1].selected
                ) { return false }

                const selectedSources = filters[2].options
                  .filter((o) => o.selected)
                  .map((o) => o.value)

                return !filters[2].options.some(o => o.selected) || story.articles
                  .map((a) => a.source)
                  .some((s) => selectedSources.indexOf(s.id) >= 0)
              })
              .sort((a, b) => {
                switch (selectedSort) {
                  case 1:
                    return b.articles.length - a.articles.length
                  default: // newest
                    return moment(b.publishedAt).diff(moment(a.publishedAt))
                }
              })
              .map((story) => (
                  <Story key={story.id} story={story} setShow={setShow} />
              ))}
        </div>
      </div>

      {
        search === '' && <Pagination date={date} setDate={setDate} />
      }
      <LoginDialog show={show} setShow={setShow} />
    </>
  )
}
