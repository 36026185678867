import { useState } from 'react'
import { StoryFragment } from '../../../generated/types'
import StoryButtons from './storybuttons'
import StoryContent from './storycontent'
import StorySources from './storysources'

function Story ({ story, setShow }: {story: StoryFragment, setShow: (show: boolean) => void}) {
  const [articleId, setArticleId] = useState(story.articles[0]?.id)

  return (
        <div className="bg-white dark:bg-gray-800 overflow-hidden sm:rounded-lg mb-4 ring-1 ring-slate-900/5 shadow-md">
            <div className="grid grid-rows-[auto_2px_auto_2px_40px] grid-cols-1 sm:grid-rows-1 sm:grid-cols-[80px_2px_minmax(0,1fr)_2px_240px] p-2 gap-2">
                <StoryButtons story={story} setShow={setShow}/>
                <div className="h-0.5 sm:h-full w-full sm:w-0.5 bg-slate-100 dark:bg-slate-900"/>
                { /*
                  //@ts-ignore */}
                <StoryContent article={story.articles.concat(story.cves).find(article => article.id === articleId) ?? story.articles[0]} />
                <div className="h-0.5 sm:h-full w-full sm:w-0.5 bg-slate-100 dark:bg-slate-900"/>
                <StorySources story={story} articleId={articleId} setArticleId={setArticleId}/>
            </div>
        </div>
  )
}

export default Story
