function Feedback () {
  return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Feedback
                    </h1>
                    <p className="mt-2 text-sm text-gray-700"></p>
                </div>
            </div>
            <div className="-mx-4 mt-8">
                <iframe
                    title="Feedback form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLScZPlruwRPem6IAeCwawPMOdAAb2244JmcHnYA_D6eeRru5bg/viewform?embedded=true"
                    className="w-full min-h-[600px]"
                >
                    Wird geladen…
                </iframe>
            </div>
        </div>
  )
}

export default Feedback
