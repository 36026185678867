import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
};

export type Article = {
  __typename?: 'Article';
  articleId: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['Date']>;
  source: Source;
  sourceId: Scalars['String'];
  story?: Maybe<Story>;
  storyId?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Cve = {
  __typename?: 'CVE';
  createdAt: Scalars['Date'];
  cveId: Scalars['String'];
  cveText: Scalars['String'];
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['Date']>;
  summary: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CrawlStatus = {
  __typename?: 'CrawlStatus';
  crawledAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  nrInDb: Scalars['Int'];
  nrInRss: Scalars['Int'];
  totalTime: Scalars['Float'];
};

export type LogInResponse = {
  __typename?: 'LogInResponse';
  accessToken?: Maybe<Scalars['String']>;
  magicTokenStatus: MagicTokenStatus;
};

export enum LogInRole {
  Superadmin = 'Superadmin',
  User = 'User'
}

export type LogInStatus = {
  __typename?: 'LogInStatus';
  role?: Maybe<LogInRole>;
  status: LogInTokenStatus;
};

export enum LogInTokenStatus {
  BadToken = 'BadToken',
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut'
}

export enum MagicTokenStatus {
  InvalidToken = 'InvalidToken',
  NotTheNewestToken = 'NotTheNewestToken',
  TokenOk = 'TokenOk'
}

export type Mutation = {
  __typename?: 'Mutation';
  addSource: Source;
  deleteSource: Source;
  markStory: Story;
  moveArticleToStory: Article;
  recrawl?: Maybe<Scalars['Boolean']>;
  updateSource: Source;
  updateUser: User;
};


export type MutationAddSourceArgs = {
  create: SourceCreate;
};


export type MutationDeleteSourceArgs = {
  id: Scalars['ID'];
};


export type MutationMarkStoryArgs = {
  mark: StoryMark;
  storyId: Scalars['ID'];
};


export type MutationMoveArticleToStoryArgs = {
  id: Scalars['ID'];
  newStoryId: Scalars['ID'];
};


export type MutationUpdateSourceArgs = {
  id: Scalars['ID'];
  update: SourceUpdate;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  update: UserUpdate;
};

export type Query = {
  __typename?: 'Query';
  getCVEs: Array<Cve>;
  getCrawls: Array<CrawlStatus>;
  getLoginStatus: LogInStatus;
  getSources: Array<Source>;
  getStories: Array<Story>;
  getUsers: Array<User>;
  logIn: LogInResponse;
  sendMagicLink?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCvEsArgs = {
  date?: InputMaybe<Scalars['Date']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetStoriesArgs = {
  date?: InputMaybe<Scalars['Date']>;
  search: Scalars['String'];
};


export type QueryLogInArgs = {
  magicToken: Scalars['String'];
};


export type QuerySendMagicLinkArgs = {
  email: Scalars['String'];
};

export type Source = {
  __typename?: 'Source';
  active: Scalars['Boolean'];
  deleted?: Maybe<Scalars['Date']>;
  homepageUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rssUrl: Scalars['String'];
};

export type SourceCreate = {
  active: Scalars['Boolean'];
  homepageUrl: Scalars['String'];
  name: Scalars['String'];
  rssUrl: Scalars['String'];
};

export type SourceUpdate = {
  active?: InputMaybe<Scalars['Boolean']>;
  homepageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rssUrl?: InputMaybe<Scalars['String']>;
};

export type Story = {
  __typename?: 'Story';
  articles: Array<Article>;
  createdAt: Scalars['Date'];
  cves: Array<Cve>;
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['Date']>;
  userToStory?: Maybe<UserInteractions>;
};

export type StoryMark = {
  markBookmarked?: InputMaybe<Scalars['Boolean']>;
  markSeen?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isBlocked: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['Date']>;
};

export type UserInteractions = {
  __typename?: 'UserInteractions';
  isBookmarked: Scalars['Boolean'];
  isSeen: Scalars['Boolean'];
  storyId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UserUpdate = {
  isBlocked?: InputMaybe<Scalars['Boolean']>;
};

export type ArticleFragment = { __typename?: 'Article', id: string, title: string, summary: string, url: string, createdAt: string, publishedAt?: string | null, source: { __typename?: 'Source', id: string, name: string } };

export type CveFragment = { __typename?: 'CVE', id: string, title: string, summary: string, cveId: string, url: string, createdAt: string, publishedAt?: string | null };

export type StoryFragment = { __typename?: 'Story', id: string, createdAt: string, publishedAt?: string | null, articles: Array<{ __typename?: 'Article', id: string, title: string, summary: string, url: string, createdAt: string, publishedAt?: string | null, source: { __typename?: 'Source', id: string, name: string } }>, cves: Array<{ __typename?: 'CVE', id: string, title: string, summary: string, cveId: string, url: string, createdAt: string, publishedAt?: string | null }>, userToStory?: { __typename?: 'UserInteractions', isBookmarked: boolean, isSeen: boolean } | null };

export type SourceFragment = { __typename?: 'Source', id: string, name: string, homepageUrl: string, rssUrl: string, active: boolean, deleted?: string | null };

export type UserFragment = { __typename?: 'User', id: string, email: string };

export type CrawlStatusFragment = { __typename?: 'CrawlStatus', id: string, nrInRss: number, nrInDb: number, totalTime: number, crawledAt?: string | null };

export type GetStoriesQueryVariables = Exact<{
  search: Scalars['String'];
  date?: InputMaybe<Scalars['Date']>;
}>;


export type GetStoriesQuery = { __typename?: 'Query', getStories: Array<{ __typename?: 'Story', id: string, createdAt: string, publishedAt?: string | null, articles: Array<{ __typename?: 'Article', id: string, title: string, summary: string, url: string, createdAt: string, publishedAt?: string | null, source: { __typename?: 'Source', id: string, name: string } }>, cves: Array<{ __typename?: 'CVE', id: string, title: string, summary: string, cveId: string, url: string, createdAt: string, publishedAt?: string | null }>, userToStory?: { __typename?: 'UserInteractions', isBookmarked: boolean, isSeen: boolean } | null }> };

export type MarkStoryMutationVariables = Exact<{
  storyId: Scalars['ID'];
  mark: StoryMark;
}>;


export type MarkStoryMutation = { __typename?: 'Mutation', markStory: { __typename?: 'Story', id: string, createdAt: string, publishedAt?: string | null, articles: Array<{ __typename?: 'Article', id: string, title: string, summary: string, url: string, createdAt: string, publishedAt?: string | null, source: { __typename?: 'Source', id: string, name: string } }>, cves: Array<{ __typename?: 'CVE', id: string, title: string, summary: string, cveId: string, url: string, createdAt: string, publishedAt?: string | null }>, userToStory?: { __typename?: 'UserInteractions', isBookmarked: boolean, isSeen: boolean } | null } };

export type SendMagicLinkQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendMagicLinkQuery = { __typename?: 'Query', sendMagicLink?: boolean | null };

export type LogInQueryVariables = Exact<{
  magicToken: Scalars['String'];
}>;


export type LogInQuery = { __typename?: 'Query', logIn: { __typename?: 'LogInResponse', magicTokenStatus: MagicTokenStatus, accessToken?: string | null } };

export type GetLoginStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoginStatusQuery = { __typename?: 'Query', getLoginStatus: { __typename?: 'LogInStatus', status: LogInTokenStatus, role?: LogInRole | null } };

export type RecrawlMutationVariables = Exact<{ [key: string]: never; }>;


export type RecrawlMutation = { __typename?: 'Mutation', recrawl?: boolean | null };

export type GetSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSourcesQuery = { __typename?: 'Query', getSources: Array<{ __typename?: 'Source', id: string, name: string, homepageUrl: string, rssUrl: string, active: boolean, deleted?: string | null }> };

export type AddSourceMutationVariables = Exact<{
  create: SourceCreate;
}>;


export type AddSourceMutation = { __typename?: 'Mutation', addSource: { __typename?: 'Source', id: string, name: string, homepageUrl: string, rssUrl: string, active: boolean, deleted?: string | null } };

export type UpdateSourceMutationVariables = Exact<{
  id: Scalars['ID'];
  update: SourceUpdate;
}>;


export type UpdateSourceMutation = { __typename?: 'Mutation', updateSource: { __typename?: 'Source', id: string, name: string, homepageUrl: string, rssUrl: string, active: boolean, deleted?: string | null } };

export type DeleteSourceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSourceMutation = { __typename?: 'Mutation', deleteSource: { __typename?: 'Source', id: string, name: string, homepageUrl: string, rssUrl: string, active: boolean, deleted?: string | null } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', id: string, email: string }> };

export type GetCrawlsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCrawlsQuery = { __typename?: 'Query', getCrawls: Array<{ __typename?: 'CrawlStatus', id: string, nrInRss: number, nrInDb: number, totalTime: number, crawledAt?: string | null }> };

export const ArticleFragmentDoc = gql`
    fragment Article on Article {
  id
  title
  summary
  url
  createdAt
  publishedAt
  source {
    id
    name
  }
}
    `;
export const CveFragmentDoc = gql`
    fragment Cve on CVE {
  id
  title
  summary
  cveId
  url
  createdAt
  publishedAt
}
    `;
export const StoryFragmentDoc = gql`
    fragment Story on Story {
  id
  createdAt
  publishedAt
  articles {
    ...Article
  }
  cves {
    ...Cve
  }
  userToStory {
    isBookmarked
    isSeen
  }
}
    ${ArticleFragmentDoc}
${CveFragmentDoc}`;
export const SourceFragmentDoc = gql`
    fragment Source on Source {
  id
  name
  homepageUrl
  rssUrl
  active
  deleted
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
}
    `;
export const CrawlStatusFragmentDoc = gql`
    fragment CrawlStatus on CrawlStatus {
  id
  nrInRss
  nrInDb
  totalTime
  crawledAt
}
    `;
export const GetStoriesDocument = gql`
    query getStories($search: String!, $date: Date) {
  getStories(search: $search, date: $date) {
    ...Story
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useGetStoriesQuery__
 *
 * To run a query within a React component, call `useGetStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetStoriesQuery(baseOptions: Apollo.QueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoriesQuery, GetStoriesQueryVariables>(GetStoriesDocument, options);
      }
export function useGetStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoriesQuery, GetStoriesQueryVariables>(GetStoriesDocument, options);
        }
export type GetStoriesQueryHookResult = ReturnType<typeof useGetStoriesQuery>;
export type GetStoriesLazyQueryHookResult = ReturnType<typeof useGetStoriesLazyQuery>;
export type GetStoriesQueryResult = Apollo.QueryResult<GetStoriesQuery, GetStoriesQueryVariables>;
export const MarkStoryDocument = gql`
    mutation markStory($storyId: ID!, $mark: StoryMark!) {
  markStory(storyId: $storyId, mark: $mark) {
    ...Story
  }
}
    ${StoryFragmentDoc}`;
export type MarkStoryMutationFn = Apollo.MutationFunction<MarkStoryMutation, MarkStoryMutationVariables>;

/**
 * __useMarkStoryMutation__
 *
 * To run a mutation, you first call `useMarkStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryMutation, { data, loading, error }] = useMarkStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *      mark: // value for 'mark'
 *   },
 * });
 */
export function useMarkStoryMutation(baseOptions?: Apollo.MutationHookOptions<MarkStoryMutation, MarkStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkStoryMutation, MarkStoryMutationVariables>(MarkStoryDocument, options);
      }
export type MarkStoryMutationHookResult = ReturnType<typeof useMarkStoryMutation>;
export type MarkStoryMutationResult = Apollo.MutationResult<MarkStoryMutation>;
export type MarkStoryMutationOptions = Apollo.BaseMutationOptions<MarkStoryMutation, MarkStoryMutationVariables>;
export const SendMagicLinkDocument = gql`
    query sendMagicLink($email: String!) {
  sendMagicLink(email: $email)
}
    `;

/**
 * __useSendMagicLinkQuery__
 *
 * To run a query within a React component, call `useSendMagicLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendMagicLinkQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendMagicLinkQuery(baseOptions: Apollo.QueryHookOptions<SendMagicLinkQuery, SendMagicLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendMagicLinkQuery, SendMagicLinkQueryVariables>(SendMagicLinkDocument, options);
      }
export function useSendMagicLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendMagicLinkQuery, SendMagicLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendMagicLinkQuery, SendMagicLinkQueryVariables>(SendMagicLinkDocument, options);
        }
export type SendMagicLinkQueryHookResult = ReturnType<typeof useSendMagicLinkQuery>;
export type SendMagicLinkLazyQueryHookResult = ReturnType<typeof useSendMagicLinkLazyQuery>;
export type SendMagicLinkQueryResult = Apollo.QueryResult<SendMagicLinkQuery, SendMagicLinkQueryVariables>;
export const LogInDocument = gql`
    query logIn($magicToken: String!) {
  logIn(magicToken: $magicToken) {
    magicTokenStatus
    accessToken
  }
}
    `;

/**
 * __useLogInQuery__
 *
 * To run a query within a React component, call `useLogInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogInQuery({
 *   variables: {
 *      magicToken: // value for 'magicToken'
 *   },
 * });
 */
export function useLogInQuery(baseOptions: Apollo.QueryHookOptions<LogInQuery, LogInQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogInQuery, LogInQueryVariables>(LogInDocument, options);
      }
export function useLogInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogInQuery, LogInQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogInQuery, LogInQueryVariables>(LogInDocument, options);
        }
export type LogInQueryHookResult = ReturnType<typeof useLogInQuery>;
export type LogInLazyQueryHookResult = ReturnType<typeof useLogInLazyQuery>;
export type LogInQueryResult = Apollo.QueryResult<LogInQuery, LogInQueryVariables>;
export const GetLoginStatusDocument = gql`
    query getLoginStatus {
  getLoginStatus {
    status
    role
  }
}
    `;

/**
 * __useGetLoginStatusQuery__
 *
 * To run a query within a React component, call `useGetLoginStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoginStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetLoginStatusQuery, GetLoginStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoginStatusQuery, GetLoginStatusQueryVariables>(GetLoginStatusDocument, options);
      }
export function useGetLoginStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoginStatusQuery, GetLoginStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoginStatusQuery, GetLoginStatusQueryVariables>(GetLoginStatusDocument, options);
        }
export type GetLoginStatusQueryHookResult = ReturnType<typeof useGetLoginStatusQuery>;
export type GetLoginStatusLazyQueryHookResult = ReturnType<typeof useGetLoginStatusLazyQuery>;
export type GetLoginStatusQueryResult = Apollo.QueryResult<GetLoginStatusQuery, GetLoginStatusQueryVariables>;
export const RecrawlDocument = gql`
    mutation recrawl {
  recrawl
}
    `;
export type RecrawlMutationFn = Apollo.MutationFunction<RecrawlMutation, RecrawlMutationVariables>;

/**
 * __useRecrawlMutation__
 *
 * To run a mutation, you first call `useRecrawlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecrawlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recrawlMutation, { data, loading, error }] = useRecrawlMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecrawlMutation(baseOptions?: Apollo.MutationHookOptions<RecrawlMutation, RecrawlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecrawlMutation, RecrawlMutationVariables>(RecrawlDocument, options);
      }
export type RecrawlMutationHookResult = ReturnType<typeof useRecrawlMutation>;
export type RecrawlMutationResult = Apollo.MutationResult<RecrawlMutation>;
export type RecrawlMutationOptions = Apollo.BaseMutationOptions<RecrawlMutation, RecrawlMutationVariables>;
export const GetSourcesDocument = gql`
    query getSources {
  getSources {
    ...Source
  }
}
    ${SourceFragmentDoc}`;

/**
 * __useGetSourcesQuery__
 *
 * To run a query within a React component, call `useGetSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetSourcesQuery, GetSourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourcesQuery, GetSourcesQueryVariables>(GetSourcesDocument, options);
      }
export function useGetSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourcesQuery, GetSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourcesQuery, GetSourcesQueryVariables>(GetSourcesDocument, options);
        }
export type GetSourcesQueryHookResult = ReturnType<typeof useGetSourcesQuery>;
export type GetSourcesLazyQueryHookResult = ReturnType<typeof useGetSourcesLazyQuery>;
export type GetSourcesQueryResult = Apollo.QueryResult<GetSourcesQuery, GetSourcesQueryVariables>;
export const AddSourceDocument = gql`
    mutation addSource($create: SourceCreate!) {
  addSource(create: $create) {
    ...Source
  }
}
    ${SourceFragmentDoc}`;
export type AddSourceMutationFn = Apollo.MutationFunction<AddSourceMutation, AddSourceMutationVariables>;

/**
 * __useAddSourceMutation__
 *
 * To run a mutation, you first call `useAddSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSourceMutation, { data, loading, error }] = useAddSourceMutation({
 *   variables: {
 *      create: // value for 'create'
 *   },
 * });
 */
export function useAddSourceMutation(baseOptions?: Apollo.MutationHookOptions<AddSourceMutation, AddSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSourceMutation, AddSourceMutationVariables>(AddSourceDocument, options);
      }
export type AddSourceMutationHookResult = ReturnType<typeof useAddSourceMutation>;
export type AddSourceMutationResult = Apollo.MutationResult<AddSourceMutation>;
export type AddSourceMutationOptions = Apollo.BaseMutationOptions<AddSourceMutation, AddSourceMutationVariables>;
export const UpdateSourceDocument = gql`
    mutation updateSource($id: ID!, $update: SourceUpdate!) {
  updateSource(id: $id, update: $update) {
    ...Source
  }
}
    ${SourceFragmentDoc}`;
export type UpdateSourceMutationFn = Apollo.MutationFunction<UpdateSourceMutation, UpdateSourceMutationVariables>;

/**
 * __useUpdateSourceMutation__
 *
 * To run a mutation, you first call `useUpdateSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourceMutation, { data, loading, error }] = useUpdateSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourceMutation, UpdateSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourceMutation, UpdateSourceMutationVariables>(UpdateSourceDocument, options);
      }
export type UpdateSourceMutationHookResult = ReturnType<typeof useUpdateSourceMutation>;
export type UpdateSourceMutationResult = Apollo.MutationResult<UpdateSourceMutation>;
export type UpdateSourceMutationOptions = Apollo.BaseMutationOptions<UpdateSourceMutation, UpdateSourceMutationVariables>;
export const DeleteSourceDocument = gql`
    mutation deleteSource($id: ID!) {
  deleteSource(id: $id) {
    ...Source
  }
}
    ${SourceFragmentDoc}`;
export type DeleteSourceMutationFn = Apollo.MutationFunction<DeleteSourceMutation, DeleteSourceMutationVariables>;

/**
 * __useDeleteSourceMutation__
 *
 * To run a mutation, you first call `useDeleteSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSourceMutation, { data, loading, error }] = useDeleteSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSourceMutation, DeleteSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSourceMutation, DeleteSourceMutationVariables>(DeleteSourceDocument, options);
      }
export type DeleteSourceMutationHookResult = ReturnType<typeof useDeleteSourceMutation>;
export type DeleteSourceMutationResult = Apollo.MutationResult<DeleteSourceMutation>;
export type DeleteSourceMutationOptions = Apollo.BaseMutationOptions<DeleteSourceMutation, DeleteSourceMutationVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  getUsers {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetCrawlsDocument = gql`
    query getCrawls {
  getCrawls {
    ...CrawlStatus
  }
}
    ${CrawlStatusFragmentDoc}`;

/**
 * __useGetCrawlsQuery__
 *
 * To run a query within a React component, call `useGetCrawlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrawlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrawlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCrawlsQuery(baseOptions?: Apollo.QueryHookOptions<GetCrawlsQuery, GetCrawlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCrawlsQuery, GetCrawlsQueryVariables>(GetCrawlsDocument, options);
      }
export function useGetCrawlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCrawlsQuery, GetCrawlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCrawlsQuery, GetCrawlsQueryVariables>(GetCrawlsDocument, options);
        }
export type GetCrawlsQueryHookResult = ReturnType<typeof useGetCrawlsQuery>;
export type GetCrawlsLazyQueryHookResult = ReturnType<typeof useGetCrawlsLazyQuery>;
export type GetCrawlsQueryResult = Apollo.QueryResult<GetCrawlsQuery, GetCrawlsQueryVariables>;