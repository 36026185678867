import {
  InboxIcon,
  UserCircleIcon,
  ViewGridAddIcon
} from '@heroicons/react/outline'
import { NavLink, Outlet } from 'react-router-dom'

const subNavigation = [
  { name: 'Users', href: 'users', icon: UserCircleIcon, current: false },
  { name: 'Sources', href: 'sources', icon: ViewGridAddIcon, current: false },
  { name: 'Crawls', href: 'crawls', icon: InboxIcon, current: false }
]

export default function Settings () {
  return (
    <>
      <div className="h-full">
        <main className="max-w-7xl mx-auto pb-10">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      isActive
                        ? 'bg-gray-50 text-blue-600 hover:bg-white group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                        : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                    }
                    // eslint-disable-next-line react/no-children-prop
                    children={({ isActive }) => (
                        <>
                            <item.icon
                                className={`flex-shrink-0 -ml-1 mr-3 h-6 w-6 ${isActive ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500'}`}
                                aria-hidden="true"
                                />
                            <span className="truncate">{item.name}</span>
                        </>)
                    }/>
                ))}
              </nav>
            </aside>

            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
