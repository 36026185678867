export default function StorySkeleton() {
    return (
        <div className="bg-white dark:bg-gray-800 overflow-hidden rounded-lg mb-4 ring-1 ring-slate-900/5 shadow-xl">
            <div className="animate-pulse grid grid-rows-[auto_2px_auto_2px_40px] grid-cols-1 sm:grid-rows-1 sm:grid-cols-[80px_2px_minmax(0,1fr)_2px_240px] p-2 gap-2">
                <div className="w-full flex sm:flex-col justify-evenly items-center row-start-5 sm:row-start-1 sm:col-start-1">
                    <div className="rounded-full bg-slate-200 dark:bg-slate-500 h-8 w-8" />
                    <span className="h-full sm:h-0.5 w-0.5 sm:w-full bg-slate-100 dark:bg-slate-900" />
                    <div className="rounded-full bg-slate-200 dark:bg-slate-500 h-8 w-8" />
                </div>

                <div className="h-0.5 sm:h-full w-full sm:w-0.5 bg-slate-100 dark:bg-slate-900" />

                <div className="p-2 row-start-1 sm:col-start-3">
                    <div className="flex justify-between">
                        <div>
                            <div className="mb-2 h-3 w-40 md:w-60 lg:w-80 bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                            <div className="mb-2 h-3 w-48 sm:hidden bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                            <div className="mb-2 h-3 w-40 sm:hidden bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                        </div>
                        <div className="mb-2 h-3 w-28 bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                    </div>
                    <div className="mb-4 h-3 w-28 bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                    <div className="mb-1 h-3 bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                    <div className="mb-1 h-3 bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                    <div className="h-3 w-20 bg-slate-200 dark:bg-slate-500 rounded-md"></div>
                </div>

                <div className="h-0.5 sm:h-full w-full sm:w-0.5 bg-slate-100 dark:bg-slate-900" />

                <div className="flex flex-col sm:flex-row row-start-3 sm:row-start-1 sm:col-start-5">
                    <div className="ml-auto order-2 flex align-center justify-center items-center sm:w-2 mb-0 sm:mr-2 -mt-5 sm:mt-0">
                        <p className="text-sm text-gray-500 dark:text-slate-400 sm:-rotate-90 h-5 px-2">
                            Sources
                        </p>
                    </div>
                    <div className="p-2">
                        <div className="h-3 w-36 bg-slate-200 dark:bg-blue-800 rounded-md"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
