import {
    CheckIcon,
    BookmarkIcon as BookmarkIconOutline,
} from "@heroicons/react/outline";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/solid";
import {
    StoryFragment,
    useMarkStoryMutation,
    useGetLoginStatusQuery,
    LogInTokenStatus,
} from "../../../generated/types";

export default function StoryButtons({
    story,
    setShow,
}: {
    story: StoryFragment;
    setShow: (show: boolean) => void;
}) {
    const { data: userLoggedIn } = useGetLoginStatusQuery();
    const [markStory] = useMarkStoryMutation();

    const buttonClick = (markBookmarked: boolean, markSeen: boolean) => {
        if (userLoggedIn?.getLoginStatus.status === LogInTokenStatus.LoggedIn) {
            markStory({
                variables: {
                    storyId: story.id,
                    mark: {
                        markBookmarked,
                        markSeen,
                    },
                },
            });
        } else {
            setShow(true);
        }
    };

    return (
        <div className="w-full flex sm:flex-col items-center row-start-5 sm:row-start-1 sm:col-start-1">
            <button
                title={
                    story.userToStory?.isSeen
                        ? "Mark as not read"
                        : "Mark as read"
                }
                className={`
                    group grow shrink-0 basis-0 flex justify-center items-center flex-col
                    ${
                        story.userToStory?.isSeen
                            ? "text-green-500"
                            : "text-gray-500 dark:text-slate-400 hover:text-gray-800 dark:hover:text-slate-200"
                    }
                  `}
                onClick={() => buttonClick(false, !story.userToStory?.isSeen)}
            >
                <CheckIcon className={`w-6 h-6`} />
                <p className={`text-xs`}>Read</p>
            </button>
            <span className="h-full sm:h-0.5 w-0.5 sm:w-full bg-slate-100 dark:bg-slate-900" />
            <button
                title={
                    story.userToStory?.isBookmarked
                        ? "Remove bookmark"
                        : "Bookmark"
                }
                className={`
                  grow shrink-0 basis-0 flex justify-center items-center flex-col
                  ${
                      story.userToStory?.isBookmarked
                          ? "text-orange-500"
                          : "text-gray-500 dark:text-slate-400 hover:text-gray-800 dark:hover:text-slate-200"
                  }
                `}
                onClick={() =>
                    buttonClick(!story.userToStory?.isBookmarked, false)
                }
            >
                {story.userToStory?.isBookmarked ? (
                    <BookmarkIconSolid className="w-6 h-6" />
                ) : (
                    <BookmarkIconOutline className="w-6 h-6" />
                )}
                <p className={`text-xs`}>Bookmark</p>
            </button>
        </div>
    );
}
