import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CogIcon } from '@heroicons/react/outline'
import { useNavigate, useParams } from 'react-router-dom'
import {
  SourceFragment,
  useAddSourceMutation,
  useDeleteSourceMutation,
  useGetSourcesQuery,
  useUpdateSourceMutation
} from '../../../generated/types'

export default function Source () {
  const { id } = useParams()
  const [source, setSource] = useState<SourceFragment>({
    homepageUrl: '',
    id: '',
    name: '',
    rssUrl: '',
    __typename: 'Source',
    active: true,
    deleted: null
  })

  useGetSourcesQuery({
    onCompleted: (data) => {
      const newSource = data.getSources.find((source) => source.id === id)
      if (newSource) setSource(newSource)
    }
  })

  const [updateSourceMutation] = useUpdateSourceMutation()
  const [addSourceMutation] = useAddSourceMutation()
  const [deleteSourceMutation] = useDeleteSourceMutation()

  const navigate = useNavigate()

  const cancelButtonRef = useRef(null)

  const saveSource = () => {
    if (source.id !== '') {
      updateSourceMutation({
        variables: { id: source.id, update: { name: source.name, homepageUrl: source.homepageUrl, rssUrl: source.rssUrl } },
        onCompleted: (data) => {
          navigate(-1)
        }
      })
    } else {
      addSourceMutation({
        variables: { create: { name: source.name, homepageUrl: source.homepageUrl, rssUrl: source.rssUrl, active: true } },
        onCompleted: (data) => {
          navigate(-1)
        }
      })
    }
  }

  const deleteSource = () => {
    if (source.id !== '') {
      deleteSourceMutation({
        variables: { id: source.id },
        onCompleted: (data) => {
          navigate(-1)
        }
      })
    }
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => navigate(-1)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                    <CogIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Edit Source
                    </Dialog.Title>
                    <div className="mt-2 text-left">
                      <>
                        <div className="mb-2">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              value={source?.name}
                              onChange={(e) =>
                                setSource({ ...source, name: e.target.value })
                              }
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Name"
                            />
                          </div>
                        </div>
                        <div className="mb-2">
                          <label
                            htmlFor="url"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Homepage
                          </label>
                          <div className="mt-1">
                            <input
                              type="url"
                              name="url"
                              id="url"
                              value={source?.homepageUrl}
                              onChange={(e) =>
                                setSource({
                                  ...source,
                                  homepageUrl: e.target.value
                                })
                              }
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="https://homepage.com"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="rss"
                            className="block text-sm font-medium text-gray-700"
                          >
                            RSS URL
                          </label>
                          <div className="mt-1">
                            <input
                              type="url"
                              name="rss"
                              id="rss"
                              value={source?.rssUrl}
                              onChange={(e) =>
                                setSource({ ...source, rssUrl: e.target.value })
                              }
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="https://homepage/rss"
                            />
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => saveSource()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-red-300 shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => deleteSource()}
                    ref={cancelButtonRef}
                  >
                    Delete
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
