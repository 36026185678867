import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Feedback from "./routes/feedback/feedback";
import Layout from "./routes/layout/layout";
import Login from "./routes/authentication/login";
import Stories from "./routes/stories/stories";
import Logout from "./routes/authentication/logout";
import Sources from "./routes/admin/sources/sources";
import Source from "./routes/admin/sources/source";
import Settings from "./routes/admin/settings";
import Users from "./routes/admin/users/users";
import Crawls from "./routes/admin/crawls/crawls";
import { useEffect } from "react";
import { About } from "./routes/about/about";

const titles: any = {
    "/stories": "News Stories",
    "/feedback": "Feedback",
    "/settings": "Settings",
    "/login": "Log In",
    "/logout": "Log Out",
    "/about": "About",
};

function App() {
    const location = useLocation();

    useEffect(() => {
        let titleMatched = false;

        for (const [key, value] of Object.entries<string>(titles)) {
            if (location.pathname.startsWith(key)) {
                document.title = "GlanceIT - " + value;
                titleMatched = true;
                break;
            }
        }

        if (!titleMatched) {
            document.title = "GlanceIT";
        }
    }, [location]);
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<About />} />
                <Route path="stories" element={<Stories />}>
                    <Route path=":storyId" element={<Stories />} />
                </Route>
                <Route path="feedback" element={<Feedback />} />

                <Route path="settings" element={<Settings />}>
                    <Route index element={<Navigate to="users" replace />} />
                    <Route path="users" element={<Users />} />
                    <Route path="sources" element={<Sources />}>
                        <Route path=":id" element={<Source />} />
                    </Route>
                    <Route path="crawls" element={<Crawls />} />
                </Route>

                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Logout />} />
            </Route>
        </Routes>
    );
}

export default App;
