import { Outlet } from 'react-router-dom'
import { useGetSourcesQuery } from '../../../generated/types'

export default function Sources () {
  const { data } = useGetSourcesQuery()

  return (
    <>
      <section aria-labelledby="billing-history-heading">
        <div className="bg-white pt-6 shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 sm:px-6 flex items-center justify-between">
            <h2
              id="billing-history-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Sources (Total: {data?.getSources.filter(source => source.active && source.deleted === null).length})
            </h2>
            <a
              type="button"
              href="/settings/sources/new"
              className="inline-flex items-center px-2.5 py-0.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Source
            </a>
          </div>
          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          Homepage
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          RSS
                        </th>
                        {/*
                                  `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                                */}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data?.getSources
                        .filter(source => source.active && source.deleted === null)
                        .map((source) => (
                        <tr key={source.id}>
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                              {source.name}
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Homepage</dt>
                                <dd className="mt-1 truncate text-gray-700">{source.homepageUrl}</dd>
                                <dt className="sr-only sm:hidden">RSS</dt>
                                <dd className="mt-1 truncate text-gray-500 sm:hidden">{source.rssUrl}</dd>
                              </dl>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {source.homepageUrl}
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {source.rssUrl}
                          </td>
                          <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href={'/settings/sources/' + source.id}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Outlet />
    </>
  )
}
