import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useGetLoginStatusLazyQuery } from '../../generated/types'

export default function Logout () {
  const [loginStatus] = useGetLoginStatusLazyQuery({
    fetchPolicy: 'network-only'
  })
  useEffect(() => {
    localStorage.setItem('authtoken', '')
    loginStatus()
  }, [])

  return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                <img
                    className="mx-auto h-12 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-2xl text-gray-900">
                    <p>You have been logged out.</p>
                    <p>It is safe to leave this page or to return to the home page</p>
                </h2>
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST">
                <div>
                    <Link
                    to="/"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Back to home
                    </Link>
                </div>
                </form>
            </div>
        </div>
  )
}
