import jwtDecode from 'jwt-decode'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  LogInTokenStatus,
  MagicTokenStatus,
  useGetLoginStatusQuery,
  useLogInLazyQuery,
  useSendMagicLinkLazyQuery
} from '../../generated/types'
import Confirmation from './components/confirmation'
import Invalid from './components/invalid'
import LoggedIn from './components/loggedin'
import SignUp from './components/signup'

type MagicJWTPayload = {
  userMail: string;
  userId: string;
  createdAt: string;
  requestIp: string;
};

export default function Login () {
  const [email, setEmail] = useState('')
  const [tokenRequested, setTokenRequested] = useState(false)

  const [searchParams] = useSearchParams()

  const [getMagicLink, getMagicLinkState] = useSendMagicLinkLazyQuery()

  const navigate = useNavigate()
  const { data: currentTokenIsValid, loading: currentTokenIsValidLoading } = useGetLoginStatusQuery()
  const [signIn, signInState] = useLogInLazyQuery({
    onCompleted: (data) => {
      if (!data.logIn) return
      localStorage.setItem('authtoken', data.logIn.accessToken || '')
      if (data.logIn.accessToken) {
        setTimeout(() => {
          navigate('/')
        }, 500)
      }
    }
  })

  const rawMagicLinkToken = searchParams.get('code')
  const magicLinkJwt = rawMagicLinkToken
    ? (jwtDecode(rawMagicLinkToken) as MagicJWTPayload)
    : undefined

  const invalidToken =
    signInState.data?.logIn.magicTokenStatus === MagicTokenStatus.NotTheNewestToken || signInState.data?.logIn.magicTokenStatus === MagicTokenStatus.InvalidToken

  return (
    !currentTokenIsValidLoading
      ? <>
      {/* We're already signed in */}
      {currentTokenIsValid?.getLoginStatus.status === LogInTokenStatus.LoggedIn && (
        <LoggedIn />
      )}

      {/* Not yet signed in */}
      {currentTokenIsValid?.getLoginStatus.status !== LogInTokenStatus.LoggedIn && !magicLinkJwt && !tokenRequested && (
        <SignUp email={email} setEmail={setEmail} getMagicLink={getMagicLink} getMagicLinkState={getMagicLinkState} setTokenRequested={setTokenRequested}/>
      )}

      {getMagicLinkState.data && !magicLinkJwt && tokenRequested && (
       <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
              <div>
                <img
                    className="mx-auto h-12 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-2xl text-gray-900 dark:text-slate-100">
                    <p>An email with a sign-up link has been sent to you under</p>
                    <p className="font-bold">{email}</p>
                </h2>
              </div>
          </div>
      </div>
      )}

      {magicLinkJwt && (
        (!invalidToken
          ? <Confirmation signIn={signIn} signInState={signInState} />
          : <Invalid />
        )
      )}
    </>
      : null
  )
}
