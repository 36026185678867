import { LockClosedIcon } from '@heroicons/react/solid'

export default function SignUp ({
  email,
  setEmail,
  getMagicLink,
  getMagicLinkState,
  setTokenRequested
}: {
  email: string;
  setEmail: (email: string) => void;
  getMagicLink: ({ variables }: { variables: any }) => void;
  getMagicLinkState: { loading: boolean };
  setTokenRequested: (status: boolean) => void;
}) {
  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-slate-100">
            Sign up with just your email
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="block w-full bg-white dark:bg-black py-2 pl-3 pr-3 border border-transparent rounded-md leading-5 text-gray-900 dark:text-slate-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white dark:focus:ring-black focus:border-white dark:focus:border-black sm:text-sm"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setTokenRequested(true)
                  getMagicLink({
                    variables: {
                      email
                    }
                  })
                }
              }}
            />
          </div>

          <div>
            <button
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={getMagicLinkState.loading}
              onClick={async () => {
                setTokenRequested(true)
                getMagicLink({
                  variables: {
                    email
                  }
                })
              }}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  aria-hidden="true"
                />
              </span>
              {getMagicLinkState.loading ? 'Please wait...' : 'Sign Up'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
