import { StoryFragment } from "../../../generated/types";

export default function StorySources({
    story,
    articleId,
    setArticleId,
}: {
    story: StoryFragment;
    articleId: string;
    setArticleId: (id: string) => void;
}) {
    return (
        <div className="flex flex-col sm:flex-row row-start-3 sm:row-start-1 sm:col-start-5">
            <div className="-mt-5 order-1 sm:ml-auto sm:order-3 flex align-center justify-center items-center sm:w-2 mb-0 sm:mr-2 sm:mt-0">
                <p className="bg-white dark:bg-gray-800 text-sm text-gray-500 dark:text-slate-400 sm:-rotate-90 h-5 px-2">
                    Sources
                </p>
            </div>
            <div className="flex flex-wrap gap-2 content-start p-2 order-2">
                {story.articles.map((article) => (
                    <button
                        key={article.id}
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            article.id === articleId
                                ? "bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100"
                                : "bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-black text-gray-800 dark:text-slate-200"
                        }`}
                        onClick={() => setArticleId(article.id)}
                    >
                        {article.source.name}
                    </button>
                ))}

                {story.cves.map((cve) => (
                    <button
                        key={cve.id}
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            cve.id === articleId
                                ? "bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100"
                                : "bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-black text-gray-800 dark:text-slate-200"
                        }`}
                        onClick={() => setArticleId(cve.id)}
                    >
                        {cve.cveId}
                    </button>
                ))}
            </div>
        </div>
    );
}
