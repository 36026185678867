import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import {
    LogInTokenStatus,
    useGetLoginStatusQuery,
} from "../../../../generated/types";
import MenuItems from "./menuitems";
import User from "./user";

export default function MenuBar({
    navigation,
    search,
    setSearch,
}: {
    navigation: { name: string; href: string }[];
    search: string;
    setSearch: (serach: string) => void;
}) {
    const { data: loginStatus } = useGetLoginStatusQuery();
    const isLoggedIn =
        loginStatus?.getLoginStatus.status === LogInTokenStatus.LoggedIn;

    const userNavigation = [
        {
            name: isLoggedIn ? "Sign out" : "Sign in",
            href: isLoggedIn ? "/logout" : "/login",
        },
    ];

    return (
        <Disclosure
            as="nav"
            className="bg-gray-800 dark:bg-gray-900 dark:border-b dark:border-slate-300/10"
        >
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between h-16">
                            <MenuItems mobile={false} navigation={navigation} />
                            <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
                                <div className="max-w-lg w-full lg:max-w-xs">
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative text-gray-400 focus-within:text-gray-600 dark:focus-within:text-slate-100">
                                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                            <SearchIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <input
                                            id="search"
                                            className="block w-full bg-white dark:bg-black py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 dark:text-slate-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white dark:focus:ring-black focus:border-white dark:focus:border-black sm:text-sm"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                            value={search}
                                            onChange={(e) =>
                                                setSearch(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <User
                                mobile={false}
                                userNavigation={userNavigation}
                                isLoggedIn={isLoggedIn}
                            />

                            <div className="-mr-2 flex md:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <MenuIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="md:hidden">
                        <MenuItems mobile={true} navigation={navigation} />
                        <User
                            mobile={true}
                            userNavigation={userNavigation}
                            isLoggedIn={isLoggedIn}
                        />
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}
