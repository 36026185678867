import { Link } from 'react-router-dom'

export default function LoggedIn () {
  return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                <img
                    className="mx-auto h-12 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                    alt="Workflow"
                />
                <h2 className="mt-6 text-center text-2xl text-gray-900 dark:text-gray-100">
                    <p>You are already logged in</p>
                </h2>
                </div>
                <form className="mt-8 space-y-6" action="#" method="POST">
                <div>
                    <Link
                    to="/"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Home
                    </Link>
                </div>
                </form>
            </div>
        </div>
  )
}
